import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import config from "./modules/_core/config";
import { AuthConfig } from "./modules/users/api/types";

declare global {
  interface Window {
    authConfig: AuthConfig;
  }
}

if (config.configSentry?.enabled && config.configSentry?.dsn) {
  require("./modules/_core/config/sentry");
}

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
