import { AjaxError } from "../../_core/api/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  assignUser,
  createUser,
  getExistingUser,
  getUsers,
  sendWelcomeEmail,
} from "../api";
import {
  AssignUserParams,
  CreateUserParams,
  ExistingUser,
  GetExistingUserResp,
  GetUsersParams,
  GetUsersResp,
  UserLite,
} from "../api/types";

export function useUsers(params: GetUsersParams, options?: any) {
  return useQuery<GetUsersResp, AjaxError>({
    queryKey: ["users", params],
    queryFn: () => getUsers(params),
    ...options,
  });
}

export function useExistingUser(email: string, options?: any) {
  return useQuery<GetExistingUserResp | null, AjaxError>({
    queryKey: ["existingUser", email],
    queryFn: () => getExistingUser(email),
    ...options,
  });
}

export function useCreateUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<string, AjaxError, CreateUserParams>({
    queryKey: ["createUser"],
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    ...options,
  });
}

export function useAssignUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<ExistingUser, AjaxError, AssignUserParams>({
    queryKey: ["assignUser"],
    mutationFn: assignUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    ...options,
  });
}

export function useSendWelcomeEmail(options?: any) {
  return useMutation<boolean, AjaxError, UserLite>({
    queryKey: ["welcomeEmail"],
    mutationFn: sendWelcomeEmail,
    ...options,
  });
}
