import { useQuery } from "@tanstack/react-query";
import { AjaxResponse } from "../../_core/api/types";
import { getBrand } from "../api";
import { Brand } from "../api/types";
import affinityLogo from "../../../images/logo-light.png";

export function useBrand(options?: any) {
  return useQuery<Brand, AjaxResponse>({
    queryKey: ["brand"],
    queryFn: () => getBrand(),
    ...options,
  });
}

export function useBrandColors(defaultColors: any) {
  const { data: brand } = useBrand();
  if (brand)
    return {
      primary: brand?.primaryColour,
      secondary: brand?.secondaryColour,
      tertiary: brand?.tertiaryColour,
      primary_text: brand?.primaryTextColour,
      secondary_text: brand?.secondaryTextColour,
      tertiary_text: brand?.tertiaryTextColour,
    };
  return defaultColors;
}

export function useBrandName() {
  const { data: brand } = useBrand();
  return brand?.name || "Hub Partner";
}

export function useLogo() {
  const { data: brand } = useBrand();
  return brand?.logoUrl || affinityLogo;
}
