import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid2 as Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Public from "./layout/Public";
import { useHubUserExist } from "../../users/hooks/auth";
import UIAlert from "./ui/UIAlert";
import UIButton from "./ui/UIButton";
import UIText from "./ui/UIText";

const NoUser = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [timer, setTimer] = useState<number>(20);
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();

  const handleLogout = useCallback(() => {
    navigate("/logout");
  }, [history]);

  useEffect(() => {
    if (isFetchedAfterMount && doesHubUserExist) {
      navigate("/");
    }
  }, [history, isFetchedAfterMount, doesHubUserExist]);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((old: number) => Math.max(old - 1, 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!timer && !doesHubUserExist) {
      handleLogout();
    }
  }, [handleLogout, timer, doesHubUserExist]);

  return (
    <Public data-testid="nouser-page" title="No User Access">
      <Grid>
        <Box width="fit-content">
          <UIAlert severity="info">
            You do not have access, please contact your administrator
          </UIAlert>
        </Box>
        <div className={classes.logoutButton}>
          <UIButton
            variant="text"
            color="inherit"
            onClick={handleLogout}
            data-testid="disabled-page-logout-button"
          >
            Logout
          </UIButton>
        </div>
        <div className={classes.countdown}>
          <UIText variant="caption">Logging out in {timer} seconds</UIText>
        </div>
      </Grid>
    </Public>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutButton: {
      textAlign: "center",
      lineHeight: "60px",
    },
    countdown: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default NoUser;
