import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Routes,
} from "react-router-dom";
import config from ".";

Sentry.init({
  dsn: config.configSentry?.dsn,
  environment: process.env.REACT_APP_STAGE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
