import { getAccounts } from "../api";
import { useQuery } from "@tanstack/react-query";
import { AjaxResponse } from "../../_core/api/types";
import { GetAccountsParams, GetAccountsResp } from "../api/types";

export function useAccounts(params: GetAccountsParams, options?: any) {
  return useQuery<GetAccountsResp, AjaxResponse>({
    queryKey: ["accounts", params],
    queryFn: () => getAccounts(params),
    ...options,
  });
}
