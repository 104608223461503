import { useCallback, useEffect, useState } from "react";
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import {Brand} from "../../brand/api/types"
import { themeConfig } from "./theme";
import { createTheme, lighten, darken } from "@mui/material/styles";
import { useBrandColors } from "../../brand/hooks";
import { getBrand } from "../../brand/api";
import actions from "../store/Actions";
import { useStore } from "../hooks";
import LoadingOverlay from "../components/LoadingOverlay";
import { useIdentityUser } from "../../users/hooks/auth";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
  }
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export default function ThemeProvider({ children }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useStore();
  // User change triggers global brand change
  const { data: identityUser } = useIdentityUser();
  const brandedTheme = useBrandedTheme();

  const setFavIcon = (icon?: string) => {
    const favicon: HTMLLinkElement | any = document.getElementById("favicon");
    if (favicon) favicon.href = icon || "/favicon.ico";
  };

  const setBrand = useCallback(
    (brand?: Brand) => {
      if (brand) {
        dispatch({ type: actions.SET_BRAND, payload: brand });
      }
      setFavIcon(brand?.faviconUrl || brand?.logoUrl);
      setIsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    setIsLoading(true);
    setBrand();
  }, [setBrand]);

  useEffect(() => {
    let isUnmounted = false;
    setIsLoading(true);
    getBrand()
      .then((resp) => {
        if (isUnmounted) return;
        setBrand(resp);
      })
      .catch(() => {
        if (isUnmounted) return;
        setBrand();
      });
    return () => {
      isUnmounted = true;
    };
  }, [setBrand, identityUser]);

  if (isLoading) return <LoadingOverlay />;
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <MUIThemeProvider theme={brandedTheme}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export function useBrandedTheme() {
  const colors = useBrandColors({
    primary: "#7a5aff",
    secondary: "#4ec4b0",
    tertiary: "#524b65",
  });

  const brandedPalette = {
    ...themeConfig?.palette,
    primary: {
      main: colors?.primary,
      light: lighten(colors?.primary, 0.5),
      dark: darken(colors?.primary, 0.2),
      contrastText: colors?.primary_text,
    },
    secondary: {
      main: colors?.secondary,
      light: lighten(colors?.secondary, 0.5),
      dark: darken(colors?.secondary, 0.2),
      contrastText: colors?.secondary_text,
    },
    tertiary: {
      main: colors?.tertiary,
      light: lighten(colors?.tertiary, 0.5),
      dark: darken(colors?.tertiary, 0.35),
      contrastText: colors?.tertiary_text,
    },
  };

  const components = {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover": {
            color: brandedPalette?.primary?.contrastText,
            backgroundColor: brandedPalette?.primary?.main,
          },
        },
      },
    },
  };

  return createTheme({
    ...themeConfig,
    palette: brandedPalette,
    components: {
      ...themeConfig.components,
      ...components,
    },
  });
}
