import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography } from "@mui/material";

const NA = () => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.ctr}>
      N/A
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      color: theme.palette.grey[200],
    },
  })
);

export default NA;
