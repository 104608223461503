import api from "../../_core/api";
import { GetAccountsParams, GetAccountsResp } from "./types";

export const getAccounts = async (params: GetAccountsParams) => {
  const { data }: { data: GetAccountsResp } = await api({
    method: "GET",
    url: "/accounts",
    params,
  });
  return data;
};
