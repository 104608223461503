import { Backdrop, CircularProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface Props {
  overlay?: boolean;
  ctrProps?: any;
  [key: string]: any;
}

function UILoader({ overlay = false, ctrProps, ...rest }: Props) {
  const classes = useStyles();
  if (overlay) {
    return (
      <Backdrop open className={classes.ctr} {...ctrProps}>
        <CircularProgress color="primary" {...rest} />
      </Backdrop>
    );
  }
  return (
    <div {...ctrProps}>
      <CircularProgress color="primary" {...rest} />
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ctr: {
      backgroundColor: "rgba(255,255,255,0.5)",
      zIndex: 100000,
    },
  })
);

export default UILoader;
