import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  AuthCallback,
  SilentAuthCallback,
} from "./modules/auth/components/AuthCallback";
import { setupIdentity } from "./modules/auth/api/identityAPI";
import { PrivateRoute } from "./modules/auth/components/PrivateRoute";
import AccountsContainer from "./modules/account/components/AccountsContainer";
import Page404 from "./modules/_core/components/Page404";
import ScrollToTop from "./modules/_core/components/ScrollToTop";
import Alerts from "./modules/_core/components/Alerts";
import Store from "./modules/_core/store/Store";
import UILoader from "./modules/_core/components/ui/UILoader";
import Logout from "./modules/_core/components/Logout";
import NoUser from "./modules/_core/components/NoUser";
import ThemeProvider from "./modules/_core/providers/ThemeProvider";
import { useHubUserExist } from "./modules/users/hooks/auth";
import UsersContainer from "./modules/users/components/UsersContainer";
import AddUserContainer from "./modules/users/components/AddUserContainer";
import { getAuthConfig } from "./modules/users/api/auth";
import { SentryRoutes } from "./modules/_core/config/sentry";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      retry: 1,
    },
  },
});

const App = () => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const authConfig = await getAuthConfig();
        window.authConfig = authConfig;

        setupIdentity(
          authConfig.partnerPortalClientId,
          authConfig.tokenIssuerUrl
        );
        setIsReady(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  if (!isReady) return <UILoader overlay />;
  return (
    <QueryClientProvider client={queryClient}>
      <Store>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </Store>
    </QueryClientProvider>
  );
};

function AppRoutes() {
  return (
    <Router>
      <Alerts />
      <ScrollToTop />
      <Redirector />
      <SentryRoutes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/no-user" element={<NoUser />} />
        <Route path="/oauth_callback" element={<AuthCallback />} />
        <Route path="/silent_oauth_callback" element={<SilentAuthCallback />} />

        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UsersContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/new"
          element={
            <PrivateRoute>
              <AddUserContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AccountsContainer />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </SentryRoutes>
    </Router>
  );
}
const Redirector = () => {
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();
  if (isFetchedAfterMount && !doesHubUserExist) {
    return <Navigate to="/no-user" replace />;
  }
  return null;
};

export default App;
