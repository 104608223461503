import { COGNITO_TOKEN_ISSUER_URL } from "./identityAPI";

export const isCognitoUser = (issuer?: string) => {
  if (issuer) {
    return issuer.startsWith(COGNITO_TOKEN_ISSUER_URL);
  } else {
    return window.authConfig?.tokenIssuerUrl.startsWith(
      COGNITO_TOKEN_ISSUER_URL
    );
  }
};
