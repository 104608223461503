import { useQuery } from "@tanstack/react-query";
import { AjaxResponse } from "../../_core/api/types";
import { getIdentityUser, doesHubUserExist } from "../api/auth";
import { IdentityUser } from "../../auth/api/types";

export function useIdentityUser(options?: any) {
  return useQuery<IdentityUser | null, AjaxResponse>({
    queryKey: ["identityUser"],
    queryFn: getIdentityUser,
    ...options,
  });
}

export function useHubUserExist(options?: any) {
  return useQuery<boolean, AjaxResponse>({
    queryKey: ["hubUserExist"],
    queryFn: doesHubUserExist,
    ...options,
  });
}
