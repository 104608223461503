import api from "../../_core/api";
import { Brand } from "./types";

export const getBrand = async () => {
  const { data }: { data: Brand } = await api({
    method: "GET",
    url: "/brands/my",
    is_brand: true,
    skip_bearer_injection: true,
  });
  return data;
};
