import React, { ReactElement } from "react";
import { login, getUser } from "../api/identityAPI";
import { IdentityUser } from "../api/types";

interface PrivateRouteProps {
  children: ReactElement<any, any> | null;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [user, setUser] = React.useState<IdentityUser | null | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    let isMounted = true;
    const checkAuthentication = async () => {
      getUser()?.then((user) => {
        if (isMounted) {
          setUser(user);
          setLoading(false);
        }
      });
    };
    checkAuthentication();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!loading && (user === null || user?.expired)) {
    login();
    return <div />;
  } else if (!loading && user !== null) {
    return children;
  } else {
    return <div />;
  }
};
