import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TextFieldProps, TextField } from "@mui/material";

function UITextInput(props: TextFieldProps) {
  const classes = useStyles();
  return (
    <TextField
      className={[classes.root, props.className].join(" ")}
      name={props.name}
      label={props.label}
      type={props.type}
      defaultValue={props.defaultValue}
      variant="outlined"
      size="small"
      fullWidth
      onChange={props.onChange}
      {...props}
    />
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
  })
);

export default UITextInput;
