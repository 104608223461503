import React from "react";
import { Grid2 as Grid, Link } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UIText from "../ui/UIText";

function Footer() {
  const classes = useStyles();
  const links: any[] = [];
  const content1 = null;
  const content2 = null;

  return (
    <footer className={classes.root} data-testid="footer">
      <Grid>
        {links.map((link: any, i: number) => (
          <React.Fragment key={link.label || i}>
            <Link
              variant="caption"
              color="inherit"
              href={link.url || undefined}
              underline="hover"
            >
              {link.label}
            </Link>
            {i !== links.length - 1 && <UIText variant="caption"> | </UIText>}
          </React.Fragment>
        ))}
      </Grid>
      <Grid>
        {Boolean(content1) && (
          <UIText variant="caption">
            {content1}
            <br />
          </UIText>
        )}
        {Boolean(content2) && <UIText variant="caption">{content2}</UIText>}
      </Grid>
    </footer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#f0f4f8",
      textAlign: "center",
      padding: theme.spacing(3),
      "& > div": {
        padding: "10px",
      },
    },
  })
);

export default Footer;
