import { AjaxError } from "../api/types";
import { useLocation } from "react-router-dom";
import {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Action, StoreContext } from "../store/Store";
import actions from "../store/Actions";
import { isEmail, isEmpty } from "../utils";

// Allows parent component to trigger actions within child component - use with caution.
export function useTriggerKey() {
  const [key, setKey] = useState(0);
  const trigger = useCallback(() => setKey((prev) => prev + 1), []);
  return [key, trigger] as const;
}

export function useCached(key: string) {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([key]);
  return data;
}

export function useReset(prefix = "") {
  const [key, setKey] = useState(0);
  const reset = () => setKey((prev) => prev + 1);
  return [prefix + "reset" + key, reset] as const;
}

export function useStore() {
  const [state, dispatch]: [any, Dispatch<Action>] = useContext(StoreContext);
  return { state, dispatch };
}

/**
 * Used to avoid triggering re-renders when passing functions as props that rely on some state.
 *
 * @example
 *
 * function Parent() {
 *  const onChange = useMemoizedCallback((value: T) => {
 *    if (someState) setSomething(true);
 * }, [someState])
 *
 *  return <Child onChange={onChange} />
 * }
 *
 */
export function useMemoizedCallback(callback: any, inputs: any = []) {
  const callbackRef = useRef(callback);
  const memoizedCallback = useCallback((...args: any[]) => {
    return callbackRef.current(...args);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatedCallback = useCallback(callback, inputs);
  useEffect(() => {
    callbackRef.current = updatedCallback;
  }, [updatedCallback]);
  return memoizedCallback;
}

/**
 * Used to provide old state values to compare with new.
 *
 * @example
 *
 * useEffectWithPrev(([prevValue1]) => {
 *    if (prevValue1 !== value1) setSomething(true);
 * }, [value1])
 *
 */
export const useEffectWithPrev = (fn: any, inputs: any[] = []) => {
  const prevInputsRef = useRef([...inputs]);
  useEffect(() => {
    fn(prevInputsRef.current);
    prevInputsRef.current = [...inputs];
  }, [fn, inputs]);
};

export const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams;
};

export const useQueryParam = (key: string, asArray: boolean = false) => {
  const params = useQueryParams();
  const param = params.get(key);
  if (asArray) {
    return param?.split?.(",")?.filter((val) => val);
  }
  return param;
};

export const useErrorAlert = (message?: string, duration?: number) => {
  const { dispatch } = useStore();
  useEffect(() => {
    if (message) {
      dispatch({
        type: actions.SET_ERROR,
        payload: { duration, message },
      });
    }
  }, [message, dispatch, duration]);
};

export const useSuccessAlert = (message?: string, duration?: number) => {
  const { dispatch } = useStore();
  useEffect(() => {
    if (message) {
      dispatch({
        type: actions.SET_SUCCESS,
        payload: { duration, message },
      });
    }
  }, [message, dispatch, duration]);
};

export const useAlert = (message?: string, duration?: number) => {
  const { dispatch } = useStore();
  useEffect(() => {
    if (message) {
      dispatch({
        type: actions.SET_ALERT,
        payload: { duration, message },
      });
    }
  }, [message, dispatch, duration]);
};

export const useApiAlerts = (
  success?: string,
  error?: AjaxError,
  isError?: boolean,
  duration?: number
) => {
  useErrorAlert(
    error?.message ||
      (isError ? "Something went wrong, please try again later" : undefined),
    duration
  );
  useSuccessAlert(success, duration);
};

export const useTableControls = (pageDefault = 0, rowsDefault = 25) => {
  const [page, setPage] = useState(pageDefault);
  const [rowsPerPage, setRowsPerPage] = useState(rowsDefault);
  const rowsPerPageOptions = [25, 50, 100];

  useEffect(() => {
    setPage(pageDefault);
    setRowsPerPage(rowsDefault);
  }, [pageDefault, rowsDefault]);

  const onTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowsPerPage(state.rowsPerPage);
        break;
      default:
        break;
    }
  };
  return {
    page,
    setPage,
    rowsPerPage,
    onTableChange,
    rowsPerPageOptions,
  };
};

export function useValidation(config: any, target: any) {
  const [validationErrors, setValidationErrors] = useState<any>({});

  const validateSingle = (key: string, val: any) => {
    let error: string = "";
    let validateFunc = config?.[key]?.validate;
    if (typeof validateFunc === "function") {
      error = validateFunc(val);
    }
    if (config?.[key]?.required && isEmpty(val)) error = "Required field";
    if (!isEmpty(val)) {
      if (config?.[key]?.maxLength && val.length > config?.[key]?.maxLength)
        error = "Maximum length is " + config?.[key]?.maxLength;
      else if (
        config?.[key]?.minLength &&
        val.length < config?.[key]?.minLength
      )
        error = "Minimum length is " + config?.[key]?.minLength;
      else if (config?.[key]?.isEmail && !isEmail(val))
        error = "Invalid email address";
    }

    setValidationErrors((prevState: any) => ({
      ...prevState,
      [key]: error,
    }));
    return error;
  };

  const validateAll = () => {
    const errors = Object.keys(config)?.map((key) =>
      validateSingle(key, target?.[key])
    );
    return errors?.filter((e) => Boolean(e));
  };

  const feedbackProps: any = {};
  Object.keys(config).forEach((key) => {
    feedbackProps[key] = {
      error: Boolean(validationErrors[key]),
      helperText: validationErrors[key],
    };
  });

  const resetValidation = () => setValidationErrors({});

  return {
    feedbackProps,
    validationErrors,
    validateSingle,
    validateAll,
    resetValidation,
  };
}
