import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_core/components/layout/Main";
import UITable from "../../_core/components/ui/UITable";
import UIButton from "../../_core/components/ui/UIButton";
import { useApiAlerts, useTableControls } from "../../_core/hooks";
import UIBack from "../../_core/components/ui/UIBack";
import { formatDate, humanize } from "../../_core/utils";
import { useSendWelcomeEmail, useUsers } from "../hooks";
import { useIdentityUser } from "../hooks/auth";
import { AccountLevel, accountLevelToString, UserLite } from "../api/types";
import { isCognitoUser } from "../../auth/api/utils";
import {
  impersonateCognitoUser,
  impersonateNonCognitoUser,
} from "../../auth/api/identityAPI";

export const UsersContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const controls = useTableControls();
  const cognitoUser = isCognitoUser();
  const { data: identityUser } = useIdentityUser();
  const [impersonatingUuid, setImpersonatingUuid] = useState<string | null>(
    null
  );
  const [impersonatingError, setImpersonatingError] = useState<any>();
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get("accountId");
  const accountCode = queryParams.get("accountCode");

  if (!accountId || !accountCode) {
    navigate(accountCode ? `/?search=${accountCode}` : "/");
  }

  const { data, isFetching, isError, error } = useUsers(
    {
      page: controls.page,
      size: controls.rowsPerPage,
      accountId: accountId || "",
    },
    { enabled: Boolean(accountId) }
  );

  const {
    data: isEmailSuccess,
    mutate: executeSendEmail,
    error: emailError,
    isError: isEmailError,
  } = useSendWelcomeEmail();

  useApiAlerts(
    isEmailSuccess ? "Welcome email sent" : undefined,
    error || emailError || impersonatingError,
    isError || isEmailError || !!impersonatingError
  );

  const impersonateHubUser = async (user: UserLite) => {
    setImpersonatingUuid(user.id);
    try {
      if (cognitoUser) {
        await impersonateCognitoUser(
          user.id ?? "",
          window.authConfig?.partnerPortalClientId,
          identityUser?.access_token ?? "",
          {
            impersonatorEmail: identityUser?.profile.email ?? "",
            impersonatorUserId: identityUser?.profile.username ?? "",
            impersonatorGivenName: identityUser?.profile.given_name ?? "",
            impersonatorFamilyName: identityUser?.profile.family_name ?? "",
          }
        );
      } else {
        await impersonateNonCognitoUser(user);
      }
    } catch (e) {
      setImpersonatingError(e);
    } finally {
      setImpersonatingUuid(null);
      setImpersonatingError(null);
    }
  };

  return (
    <Main
      title={`Users (${accountCode})`}
      data-testid="users-container"
      action={{
        label: "Add User",
        startIcon: <AddIcon />,
        onClick: () =>
          navigate("/users/new", { state: { accountId, accountCode } }),
      }}
    >
      <div className={classes.root}>
        <UIBack label="Back To Accounts" />
        <UITable
          className={classes.table}
          isLoading={isFetching}
          columns={[
            "Name",
            "Email",
            "User Access",
            "Last Activity",
            "Validated",
            "Status",
            "",
            "",
          ]}
          data={data?.content}
          rowCallback={(u) => [
            u.name,
            u.email,
            u.userAccess.map((accountLevel: AccountLevel) => accountLevelToString[accountLevel] || "Unknown").join(", "),
            formatDate(u.lastActivity),
            u.validated ? "Yes" : "No",
            humanize(u.status || ""),
            u.validated || cognitoUser ? (
              " "
            ) : (
              <UIButton
                data-testid="resend-btn"
                onClick={() => executeSendEmail(u)}
                className={classes.resend}
              >
                Resend Welcome Email
              </UIButton>
            ),
            <UIButton
              data-testid="impersonate-btn"
              color="secondary"
              isLoading={impersonatingUuid === u.id}
              disabled={impersonatingUuid === u.id}
              onClick={() => impersonateHubUser(u)}
            >
              Masquerade
            </UIButton>,
          ]}
          options={{
            ...controls,
            count: data?.totalElements,
          }}
        />
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 8,
    "& table tr td:last-child": {
      textAlign: "right",
    },
  },
  table: {
    paddingTop: 22,
  },
  resend: {
    minWidth: 180,
  },
}));

export default UsersContainer;
